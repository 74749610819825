import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import { Container, Row } from 'react-bootstrap'
import "../assets/styles/offer.css"
import OfferRow from './_main/objectives/OfferRow';
const objectivesData = [
  {

    title: "Full Exit (100% Stake Sale)",
    text: "Helping business owners sell their entire company with ease and confidence.",
  },
  {

    title: "	Partial Stake Sale",
    text: "Assisting businesses in raising capital by selling a portion of their company while retaining control.",
  },
  {

    title: "Business Acquisition Assistance",//Strong customized search option
    text: " Connecting investors with lucrative business opportunities and providing support throughout the acquisition process.",
  },
  {

    title: "Valuation Report and Deck Preparation", 
    text: "Creating professional valuation reports and investor decks to attract buyers and investors and negotiate favorable terms.",
  }
];
const Offer = () => {
    return (
        <div className='section-container' id="offer">
          <AnimationOnScroll animateIn='animate__fadeInUp' animateOnce={true}>
            <Container>
              <div className="section-title text-center">
                <h2>    We Offer</h2>
              </div>
              <Row>
                {
                  objectivesData.map((objective, index) => {
                    return <OfferRow objective={objective} num={index} />
                  })
                }
              </Row>
            </Container>
          </AnimationOnScroll>
        </div>
      )
    }

export default Offer
