import React, { useEffect } from 'react'
import NavMenu from '../components/_main/NavMenu';
import Footer from '../components/Footer';

const UserPolicy = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    });
    return (
        <>
            <NavMenu />

            <div className="main-container bg-white">
                <div className="container-fluid bg-theme-color">
                    <div className="container section-title py-5 mb-3">
                        <p className="text-white">Users Policy</p>
                    </div>
                </div>
                <div className="container tcBlock">
                    <p className="font18">
                        Thank you for visiting Buy & Sell Business.
                        <br />
                        <br />
                        Welcome to Buy and Sell Business! By using our app, you agree to adhere to the following User Policy. This policy outlines acceptable behaviors, content guidelines, and responsibilities for users to ensure a safe and effective platform for buying and selling businesses.



                    </p>
                </div>

                <div className="container tcBlock">
                    <p className="font12 bold" style={{
                        fontSize: "18px",
                        fontWeight: "bold"
                    }}>
                        1. Acceptable Use of the Platform
                    </p>
                    <p className="font12 bold" style={{
                        fontSize: "16px",
                        fontWeight: "bold"
                    }}>
                        1.1.  Eligibility
                    </p>
                    <p className="font18 subTcBlock">
                        To use Buy and Sell Business, you must be at least 18 years old or have the consent of a parent or guardian. By creating an account, you confirm that you meet these requirements.
                    </p>



                    <p className="font12 bold" style={{
                        fontSize: "16px",
                        fontWeight: "bold"
                    }}>
                        1.2.  Account Responsibility
                    </p>
                    <p className="font18 subTcBlock">
                        You are responsible for maintaining the confidentiality of your account details and for all activities that occur under your account. Notify us immediately if you suspect any unauthorized use of your account.
                    </p>


                    <p className="font12 bold" style={{
                        fontSize: "16px",
                        fontWeight: "bold"
                    }}>
                        1.3.  Prohibited Activities
                    </p>
                    <p className="font18 subTcBlock">
                        Users must not:
                    </p>
                    <div className="container">
                        <ul className="subTcBlock" style={{ listStyleType: "disc" }}>
                            <li className="font18">
                                Engage in fraudulent activities, including the submission of false information or misrepresenting a business.
                            </li>
                            <li className="font18">
                                Use the platform for illegal purposes or in violation of any applicable laws or regulations.
                            </li>
                            <li className="font18">
                                Harass, intimidate, or discriminate against other users based on race, ethnicity, religion, gender, sexual orientation, disability, or any other protected characteristic.
                            </li>
                            <li className="font18">
                                Attempt to interfere with the proper functioning of the app, including hacking, injecting malicious code, or disrupting server operations.

                            </li>
                            <li className="font18">
                                Post, share, or distribute content that is obscene, defamatory, threatening, or otherwise inappropriate.
                            </li>
                        </ul>
                    </div>
                </div>
                {/* 2. HOW DO WE PROCESS YOUR INFORMATION? */}
                <div className="container tcBlock">
                    <p className="font12 bold" style={{
                        fontSize: "18px",
                        fontWeight: "bold"
                    }}>                2. Content Guidelines
                    </p>
                    <p className="font12 bold" style={{
                        fontSize: "16px",
                        fontWeight: "bold"
                    }}>
                        2.1.  Business Listings
                    </p>

                    <p className="font18 subTcBlock">
                        Business listings must:              </p>
                    <div className="container">
                        <ul className="subTcBlock" style={{ listStyleType: "disc" }}>

                            <li className="font18">
                                Provide accurate, up-to-date information about the business, including financial data, market position, and growth potential.                  </li>
                            <li className="font18">
                                Avoid including false or misleading information that could deceive potential buyers.                  </li>
                            <li className="font18">
                                Be free from offensive or inappropriate content, including language and images.                  </li>


                        </ul>
                    </div>



                    <p className="font12 bold" style={{
                        fontSize: "16px",
                        fontWeight: "bold"
                    }}>
                        2.2.  User-Generated Content
                    </p>

                    <p className="font18 subTcBlock">
                        Users are responsible for any content they post, including comments, reviews, and messages. All content must:            </p>
                    <div className="container">
                        <ul className="subTcBlock" style={{ listStyleType: "disc" }}>

                            <li className="font18">
                                Be truthful and reflect your genuine opinion or experience.
                            </li>
                            <li className="font18">
                                Not infringe on the intellectual property rights of others.                          </li>
                            <li className="font18">
                                Not contain personal information about other individuals without their consent.                  </li>

                        </ul>
                    </div>
                    <p className="font12 bold" style={{
                        fontSize: "16px",
                        fontWeight: "bold"
                    }}>
                        2.3.  Reporting Violations
                    </p>

                    <p className="font18 subTcBlock">

                        If you encounter content or behavior that violates this policy, report it to us through the in-app reporting feature or contact our support team at support@buysellbusines.com. We will review reports and take appropriate action.
                    </p>


                </div>




                <div className="container tcBlock">
                    <p className="font12 bold" style={{
                        fontSize: "18px",
                        fontWeight: "bold"
                    }}>                3.  Platform Responsibilities
                    </p>
                    <p className="font12 bold" style={{
                        fontSize: "16px",
                        fontWeight: "bold"
                    }}>
                        3.1. Verification of Listings
                    </p>
                    <p className="font18 subTcBlock">

                        While we strive to verify the accuracy of business listings, we do not guarantee the completeness or accuracy of any information provided. Buyers are encouraged to perform their own due diligence before making any investment decisions.                </p>



                    <p className="font12 bold" style={{
                        fontSize: "16px",
                        fontWeight: "bold"
                    }}>
                        3.3. Support Services              </p>
                    <p className="font18 subTcBlock">

                        We offer support for issues related to the use of the app and general inquiries. For support, contact us at support@buysellbusines.com.
                    </p>


                    <p className="font12 bold" style={{
                        fontSize: "16px",
                        fontWeight: "bold"
                    }}>
                        3.4. Policy Updates            </p>
                    <p className="font18 subTcBlock">

                        We may update this User Policy from time to time. Any changes will be communicated through the app or via email. Continued use of the platform constitutes acceptance of the updated policy.                </p>



                </div>




                <div className="container tcBlock">
                    <p className="font12 bold" style={{
                        fontSize: "18px",
                        fontWeight: "bold"
                    }}>                4.  Dispute Resolution
                    </p>
                    <p className="font12 bold" style={{
                        fontSize: "16px",
                        fontWeight: "bold"
                    }}>
                        4.1. Dispute Procedures              </p>
                    <p className="font18 subTcBlock">

                        In the event of a dispute between users, we encourage resolving the issue directly through communication. We are not liable for resolving disputes but may provide support in facilitating communication.                </p>

                    <p className="font12 bold" style={{
                        fontSize: "16px",
                        fontWeight: "bold"
                    }}>
                        4.2. Governing Law              </p>
                    <p className="font18 subTcBlock">

                        This User Policy is governed by the laws of [Your Country/State]. Any legal disputes will be resolved in the courts located in [Your City/State/Country].


                    </p>

                </div>




                <div className="container tcBlock">
                    <p className="font12 bold" style={{
                        fontSize: "18px",
                        fontWeight: "bold"
                    }}>                5. Termination of Service
                    </p>
                    <p className="font12 bold" style={{
                        fontSize: "16px",
                        fontWeight: "bold"
                    }}>
                        5.1. Account Termination             </p>
                    <p className="font18 subTcBlock">

                    We reserve the right to suspend or terminate accounts that violate this User Policy or engage in inappropriate behavior. If terminated, you will no longer have access to your account or any related services.
                    </p>
                    <p className="font12 bold" style={{
                        fontSize: "16px",
                        fontWeight: "bold"
                    }}>
                        5.2. App Removal      
                               </p>
                    <p className="font18 subTcBlock">

                    We may remove your access to the app if you breach the terms of this policy or for any other reason at our discretion.

                    </p>

                </div>




                <div className="container tcBlock">
                <p className="font12 bold" style={{
                        fontSize: "18px",
                        fontWeight: "bold"
                    }}>             6. Contact Us
          </p>
          <p className="font18 subTcBlock">
          For questions about this User Policy or to report issues, please contact us at:          </p>
          <div className="container">
  <ul className="subTcBlock" style={{ listStyleType: "disc" }}>
    <li className="font18">
      Email: <a href="mailto:Support@buysellbusines.com" style={{ color: "blue", textDecoration: "none" }}>Support@buysellbusines.com</a>
    </li>
    <li className="font18">
      Website: <a href="http://www.buysellbusines.com" style={{ color: "blue", textDecoration: "none" }}>buysellbusines.com</a>
    </li>
  </ul>
</div>

        </div>





            </div>

            <Footer />
        </>
    );
};

export default UserPolicy
