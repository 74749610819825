import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import { Container, Row } from 'react-bootstrap'
import ObjectiveRow from './_main/objectives/ObjectiveRow';

const objectivesData = [
  {

    title: "Facilitate Seamless Business Transactions",
    text: " To provide a user-friendly platform that simplifies the process of buying and selling small and medium-sized businesses, ensuring seamless and efficient transactions for all users. ",
  },
  {

    title: "Connect Investors with Opportunities",
    text: " To bridge the gap between investors and business owners by offering a robust marketplace where investors can discover and evaluate lucrative business opportunities. ",
  },
  {

    title: "Offer Comprehensive Support Services",//Strong customized search option
    text: "To provide expert guidance and support throughout the buying and selling process, including valuation, negotiation, and deal structuring, ensuring that our clients achieve their business goals.  ",
  },
  {

    title: "Ensure Data Security and Confidentiality", 
    text: "  To maintain the highest standards of data security and confidentiality, protecting the sensitive information of our users and ensuring that all transactions are conducted securely.",
  },
  {

    title: "Promote Growth and Expansion",
    text: " To help business owners and investors achieve growth and expansion by facilitating strategic partnerships, partial stake sales, and access to new capital through our platform. ",
  },
  {

    title: "Deliver Accurate and Professional Valuations",
    text: " To provide precise and professional valuation reports and investor decks, enabling business owners to present their companies attractively and negotiate favorable terms with potential buyers or investors. ",
  }
];

const Objectives = () => {
  return (
    <div className='section-container' id="objectives">
      <AnimationOnScroll animateIn='animate__fadeInUp' animateOnce={true}>
        <Container>
          <div className="section-titles text-center"
          
          style={{
            marginBottom:"5rem"
          }}
          >
            <h2>Our Objectives</h2>
        
          </div>
          <Row>
            {
              objectivesData.map((objective, index) => {
                return <ObjectiveRow objective={objective} num={index} />
              })
            }
          </Row>
        </Container>
      </AnimationOnScroll>
    </div>
  )
}

export default Objectives