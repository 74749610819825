import React, { useEffect } from "react";
import NavMenu from "../components/_main/NavMenu";
import "../assets/styles/privacy.css";
import Footer from "../components/Footer";
import { NavLink } from "react-bootstrap";
import { Link } from "react-router-dom";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });
  return (
    <>
      <NavMenu />

      <div className="main-container bg-white">
        <div className="container-fluid bg-theme-color">
          <div className="container section-title py-5 mb-3">
            <p className="text-white">Refund & Cancellation Policy</p>
          </div>
        </div>




        <div className="container tcBlock">
        <h3 className="font18 extraBold"> Refund & Cancellation</h3>
          <p className="font18">
          Our refund policy ensures that the refund process will be completed within 5-7 working days.
           The refunded amount will be credited directly to your bank account.
          </p>
        </div>




        <div className="container tcBlock">
          <h4 className="font18 extraBold"> CONTACT US</h4>

          <p className="font18 subTcBlock">
          For any queries or support, please reach out to us via our  contact number, email address:

          </p>
          <div className="container">
            <ul className="subTcBlock" style={{ listStyleType: "disc" }}>
              <li className="font18">
                By Email:{" "}
                <a
                  href="mailto:Support@buysellbusines.com"
                  style={{ color: "blue", textDecoration: "none" }}
                >
                  Support@buysellbusines.com
                </a>
              </li>
              <li className="font18">
                By Phone :{" "}
                <a
                  href="http://www.buysellbusines.com"
                  style={{ color: "blue", textDecoration: "none" }}
                >
          +91 96731 10806
                </a>
              </li>
              <li className="font18">
                By Address :{" "}
                <a
                  href="http://www.buysellbusines.com"
                  style={{ color: "blue", textDecoration: "none" }}
                >
         A1101, primera wagholi Pune 412207
                </a>
              </li>
       
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
