import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import '../assets/styles/Hero_slider.css'
import { Fade } from 'react-awesome-reveal'; 
import { Link } from "react-router-dom";
import { GrAppleAppStore } from "react-icons/gr";
import { BiLogoPlayStore } from "react-icons/bi";
import NavMenu from './_main/NavMenu';
import image from '../assets/images/iPhone 13 & 14 - 4.png'


const Hero_slider = () => {
  return (
    <>
    <div>  <NavMenu />
    </div>
    

      <section className='hero-slider header-hero bg_cover d-lg-flex align-items-center'>
    

        <div className="container">
          <div className="row align-items-center justify-content-center justify-content-lg-between">
            <div className="col-6 ">
              <div className="header-hero-content">
                <h3 className="
                header-title" style={{
                 color:"white"
                }} >
                  <span style={{
                      fontSize:"48px"
                  }}>Buy & Sell Business</span> <br/>RUNNING BUSINESS ON SELL
                </h3>
              <p className='para' style={{
                color:"white",
              }}>
              Welcome to Buy and Sell Business . We are committed to protecting your personal information and your right to privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information 
              when you visit our website  and use our app. Please read this privacy policy carefully.
              </p>

<div className="text-center text-lg-start">
                    <Link
                      href="#about"
                      className="btn-get-started me-2 animate__animated animate__fadeInLeft animate__delay-1s"
                    >
                      {" "}
                      <BiLogoPlayStore /> Play Store
                    </Link>
                    <Link
                      href="#about"
                      className="btn-get-started animate__animated animate__fadeInRight animate__delay-1s"
                    >
                      {" "}
                      <GrAppleAppStore /> App Store
                    </Link>
                  </div>


              </div>
            </div>
            <div className="col-4  col-sm-6 col-10">
              <div className="header-image">
                <img src={image} alt="app" className="image" />
              
              </div>
            </div>
          </div>
        </div>
        <div className="header-shape-1"></div>
       
      </section>
    </>
  )
}

export default Hero_slider
