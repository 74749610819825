import { Container, Nav, Navbar } from "react-bootstrap";
import imgLogo from "../../assets/images/Buy and sell business (5) 1.png";
import { Link } from "react-scroll";
import { Link as RouterLink, useLocation } from "react-router-dom";

const NavMenu = () => {
  const location = useLocation();

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary fixed-top">
      <div className="container">
        <RouterLink className="navbar-brand" to={"/"}>
          <img className="brand-logo" src={imgLogo} alt="" />
          <span>Buy & Sell Business</span>
        </RouterLink>
      
        {location?.pathname === "/" && (
          <>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    duration={1000}
                      active="true"
                    spy={true}
                    to="home"
                    smooth={true}
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    duration={1000}
                     active="true"
                    spy={true}
                    to="services"
                    smooth={true}
                  >
                    Services
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    duration={1000}
  active="true"
                    spy={true}
                    to="objectives"
                    smooth={true}
                  >
                    Objectives
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    duration={1000}
  active="true"
                    spy={true}
                    to="Offer"
                    smooth={true}
                  >
               Offer
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className="nav-link"
                    duration={1000}
                    active="true"
                    spy={true}
                    to="about"
                    smooth={true}
                  >
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    duration={1000}
  active="true"
                    spy={true}
                    to="contact"
                    smooth={true}
                  >
                    Contact
                  </Link>
                </li>

             


              </ul>
            </div>
          </>
        )}
      </div>
    </nav>
  );
};

export default NavMenu;
