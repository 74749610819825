import React from 'react';
import { Col } from 'react-bootstrap';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import '../../../assets/styles/offer.css';

const OfferRow = ({ num, objective }) => { // Change 'offer' to 'objective'
    return (
        <Col sm={12} md={6} lg={4} key={"offer-" + num}>
            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
                <div className='offer-card'>
                        <div className='offer-card-title text-center'>{objective.title}</div> {/* Change 'offer' to 'objective' */}
                    <div className="offer-card-body">
                        <div className='offer-card-text'>{objective.text}</div> {/* Change 'offer' to 'objective' */}
                    </div>
                </div>
            </AnimationOnScroll>
        </Col>
    );
}

export default OfferRow;
