import React, { useEffect } from "react";
import NavMenu from "../components/_main/NavMenu";
import "../assets/styles/privacy.css";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });
  return (
    <>
      <NavMenu />

      <div className="main-container bg-white">
        <div className="container-fluid bg-theme-color">
          <div className="container section-title py-5 mb-3">
            <p className="text-white">Privary Policy</p>
          </div>
        </div>
        <div className="container tcBlock">
          <p className="font18">
            Thank you for visiting Buy & Sell Business.
            <br />
            <br/>
            Welcome to Buy and Sell Business . We are committed to protecting your personal information 
            and your right to privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website www.buysellbusines.com and use our app. Please read this privacy policy carefully.
            <br />
            
            BUY & SELL BUSINESS PVT LTD <br />
          
          </p>
        </div>
       
        <div className="container tcBlock">
          <p className="font18 extraBold">
            1. Information We Collect
          </p>
          <p>We may collect the following types of information:</p>
          <div className="container">
            <ul className="subTcBlock" style={{ listStyleType: "disc" }}>
              <li className="font18">
             <span style={{
              fontWeight:"bold"
             }}> Personal Information:</span> Name, email address, phone number, and other contact details.
              </li>
              <li className="font18">
              <span style={{
              fontWeight:"bold"
             }}>Business Information:</span> Business details, financial information, and other relevant data.
              </li>
              <li className="font18">
              <span style={{
              fontWeight:"bold"
             }}>Usage Data: </span>Information about how you use our website and app, such as your IP address, browser type, operating system, and access times.
              </li>
              <li className="font18">
        	    <span style={{
              fontWeight:"bold"
             }}>Cookies and Tracking Technologies:</span>
              We use cookies to track your activity on our service and hold certain information.
               
              </li>
            </ul>
          </div>
        </div>
        {/* 2. HOW DO WE PROCESS YOUR INFORMATION? */}
        <div className="container tcBlock">
          <p className="font18 extraBold">
            2. How We Use Your Information
          </p>
          <p className="font18 subTcBlock">
          We use the information we collect for various purposes, including:
          </p>
          <div className="container">
            <ul className="subTcBlock" style={{ listStyleType: "disc" }}>
 
              <li className="font18">
              	To provide, operate, and maintain our service.
              </li>
              <li className="font18">
              	To improve, personalize, and expand our service.
              </li>
              <li className="font18">
              	To communicate with you, including customer service and support.
              </li>
              <li className="font18">
              	To process your transactions and manage your orders.
              </li>
              <li className="font18">
            	To send you marketing and promotional communications.
              </li>
              <li className="font18">
            	To comply with legal obligations and protect our rights.
              </li>
            
            </ul>
          </div>
        </div>
        {/* 3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION? */}
        <div className="container tcBlock">
          <p className="font18 extraBold">
            3. How We Share Your Information
          </p>
          <p className="font18 subTcBlock">
          We may share your information with:
          </p>
          <div className="container">
            <ul className="subTcBlock" style={{ listStyleType: "disc" }}>
              <li className="font18">
              <span style={{
              fontWeight:"bold"
             }}>	Service Providers:</span> Third-party vendors and service providers who perform services on our behalf.
              </li>
              <li className="font18">
              <span style={{
              fontWeight:"bold"
             }}>	Business Transfers:</span> In connection with or during negotiations of any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
              </li>
              <li className="font18">
              <span style={{
              fontWeight:"bold"
             }}>Legal Requirements:</span> If required by law or in response to valid requests by public authorities.
              </li>
           
            </ul>
          </div>
        </div>
        {/* 4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION? */}
        <div className="container tcBlock">
          <p className="font18 extraBold">
            4. Data Security
          </p>
          <p className="font18 subTcBlock">
          We implement reasonable security measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or method of electronic storage is 100% secure.
          </p>
         
        </div>
        {/* 5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES? */}
        <div className="container tcBlock">
          <p className="font18 extraBold">
            5. Your Privacy Rights
          </p>
          <p className="font18 subTcBlock">
          Depending on your location, you may have the following rights regarding your personal information:
          </p>
          <div className="container">
            <ul className="subTcBlock" style={{ listStyleType: "disc" }}>
              <li className="font18">
              	The right to access and receive a copy of your personal data.
              </li>
              <li className="font18">
              	The right to request correction of any inaccurate personal data.
              </li>
              <li className="font18">
              The right to request the deletion of your personal data
              </li>
              <li className="font18">
              	The right to object to the processing of your personal data.
              </li>
              <li className="font18">
              	The right to withdraw consent at any time.
              </li>
            </ul>
          </div>

        </div>
        {/* 6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? */}
        <div className="container tcBlock">
          <p className="font18 extraBold">
            6. Third-Party Links
          </p>
          <p className="font18 subTcBlock">
          Our service may contain links to other websites that are not operated by us. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
          </p>
        </div>
        {/* 7. HOW DO WE HANDLE YOUR SOCIAL LOGINS? */}
        <div className="container tcBlock">
          <p className="font18 extraBold">
            7. Changes to This Privacy Policy
          </p>
          <p className="font18 subTcBlock">
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. Changes are effective when they are posted on this page.
           </p>
        </div>
       
    
        <div className="container tcBlock">
          <p className="font18 extraBold">
            9. Contact Us
          </p>
          <p className="font18 subTcBlock">
          If you have any questions about this Privacy Policy, please contact us:
          </p>
          <div className="container">
  <ul className="subTcBlock" style={{ listStyleType: "disc" }}>
    <li className="font18">
      By email: <a href="mailto:Support@buysellbusines.com" style={{ color: "blue", textDecoration: "none" }}>Support@buysellbusines.com</a>
    </li>
    <li className="font18">
      By visiting this page on our website: <a href="http://www.buysellbusines.com" style={{ color: "blue", textDecoration: "none" }}>buysellbusines.com</a>
    </li>
  </ul>
</div>

        </div>
      </div>

      <Footer />
    </>
  );
};

export default Privacy;
